import axios from "axios";
const VERSION = "v1";

const BASE_URL="https://app.fliber.in";
const ADVISOR_URL = "https://mfapi.advisorkhoj.com";

const options = {
  headers: { "Content-Type": "application/json" },
};

const getUrl = (isadvisor, api) => {
  let url = '';
  if (!isadvisor) {
    url = `${BASE_URL}/${VERSION}/${api}`;
  } else {
    url = `${ADVISOR_URL}/${api}`
  }

  return url;
}

function apiCall(api, method, data = {}, isadvisor = false, options = {}) {
  const url = getUrl(isadvisor, api);
  return new Promise((resolve, reject) => {
    console.log('url:', url, 'method:', method);

    // Combine data and options into a single config object
    const config = {
      method,
      url,
      data,
      headers: options.headers || {}, // Ensure headers are included
    };

    axios(config)
      .then((response) => {
        console.log({ response });
        resolve(response);
      })
      .catch((error) => {
        console.log("backend/apiservice.js Error:", error);
        reject(error);
      });
  });
}


export { apiCall };
