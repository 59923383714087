import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class GoalsApi {

  static options(){

    const token = localStorage.getItem('userToken');
    
    if (!token || token.trim() === '') {
        console.log('Token is not present or is empty');
        return Promise.reject(new Error('Token is missing or empty'));
    }
    
    const bearerToken = token.replace(/"/g, ''); 
    const options = {
        headers: {
            'Authorization': `Bearer ${bearerToken}`, 
            'Content-Type': 'application/json'
        }
    };
    
    return options;
}

  static createGoal(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `ChooseGoals/?userid=${data.userid}&goalcategory=${data.goalcategory}&operation=${data.operation}`,
        'POST',
        data,
        false, 
        this.options()
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static viewExpectedAmt(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `ChooseGoals/?userid=${data.userid}&goalcategory=${data.goalcategory}&operation=${data.operation}`,
        'POST',
        data,
        false,
        this.options()
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateGoal(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/?userid=${data.userid}&goalid=${data.id}&goalcategory=${data.goalcategory}`, 'PATCH', data, false, this.options())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateCategoryStatus(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/update_category_status?id=${data.id}&goalbucketcode=${data.goalbucketcode}&goalpriority=${data.goalpriority}`, 'PATCH',null, false, this.options())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteGoal(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/${data.id}`, 'DELETE', data, false, this.options())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsByUser(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/${data.uuid}`, 'GET', null, false, this.options())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsByCategory(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/${data.category}/${data.uuid}`, 'GET', null, false, this.options())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsConfigInfo() {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/get/goals/config`, 'GET',  null, false, this.options())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default GoalsApi;
