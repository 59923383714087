import React from "react";
import { t } from "i18next";
import BaseScrn from '../basescrn';
import { AlertNotify, LoginForm } from "../components";
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";
import { resetSelectedUserDetail, resetUserCashLadderDetail, resetUserDetail, setUserDetail } from "../common";
import { SECURITY_LEVEL, USER_ROLE } from "../constants";

class LoginScrn extends BaseScrn {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            showToast: false,
            toastMessage: "",
            showProgress: false,
            progressMessage: ""
        }
    }

    componentDidMount() {
        //When admin came out from the user screens,remove selectedUserDetail from localstorage
        resetUserDetail();
        resetSelectedUserDetail();
        resetUserCashLadderDetail();
    }

    onPressForgotPwd() {
        this.props.navigate('/resetpin')
    }
    checkIsPasswordValid(data) {
        let isValid = true;
        let message = "";
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s)/;
        if (!regex.test(data.password)) {
            isValid = false;
            message = t("Password should contain atleast one special character,one digit,one capital, one small letter and should not include space.");
        }
        return { isValid, message };
    }

    onPressLogin(emailId, pwd) {
        const data = {
            "email": emailId,
            "password": pwd,
        }
        const isDataValid = this.checkIsPasswordValid(data);
        if (isDataValid.isValid) {
            this.setState({ showProgress: true }, () => {
                Bl.User.userLogin(data).then((response) => {
                    const userDetail = response.User;
                    const userToken = response.token
                    setUserDetail(userDetail)
                    localStorage.setItem("userToken", JSON.stringify(userToken))
                    this.setState({ showProgress: false }, () => {
                        if (userDetail?.securitylevel === SECURITY_LEVEL.RESTRICTED && userDetail?.role === USER_ROLE.CUSTOMER) {
                            this.props.navigate('/dashboard')
                        } else if (userDetail?.securitylevel === SECURITY_LEVEL.ADMIN || userDetail?.role === USER_ROLE.ADVISOR) {
                            this.props.navigate('/customermgmt')
                        } else {
                            AlertNotify.info('Please contact the admin to access the app')
                        }
                    })
                }).catch((error) => {
                    this.showToast(t('Please enter valid userid & password'));
                    logger.error({ error })
                })
            })
        }
        else {
            this.showToast(isDataValid.message)
        }
    }

    renderFormContentView() {
        return (
            <div className="bs_formContentView">
                <LoginForm
                    navigate={this.props.navigate}
                    onPressLogin={this.onPressLogin.bind(this)}
                    onPressForgotPwd={this.onPressForgotPwd.bind(this)}
                />
            </div>
        );
    }
}

export default LoginScrn;